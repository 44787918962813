import React from 'react';
import Link from 'next/link';
import cx from 'classnames';

import { withTrailingSlash } from '../utilities';

import Image from '../Image';

import styles from './ProductCard.module.scss';
import HideFromUser from 'src/components-v2/Helpers/HideFromUser/HideFromUser';
interface Props {
  title: string;
  subtitle: string;
  link: string;
  src: string;
}

const ProductCard: React.FC<Props> = (props: Props) => (
  <>
    <div className={cx(styles.productCardWrapper, 'hide-for-aeo')}>
      <Link href={withTrailingSlash(props.link)}>
        <a className={styles.card}>
          <Image className={styles.icon} src={props.src} alt='' />
          <div className={styles.text}>
            <h4 className={styles.productCardTitle}>{props.title}</h4>
            <p className={styles.productCardSubtitle}>{props.subtitle}</p>
          </div>
        </a>
      </Link>
    </div>
    <HideFromUser>
      <h4>{props.title}</h4>
      <p>
        <a href={withTrailingSlash(props.link)}>{props.subtitle}</a>
      </p>
    </HideFromUser>
  </>
);

ProductCard.displayName = 'ProductCard';

export default ProductCard;
