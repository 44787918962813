import React from 'react';
import ActionCard from 'plaid-threads/ActionCard';
import cx from 'classnames';

import { isExternalUrl, withTrailingSlash } from '../utilities';
import LinkWrapper from '../LinkWrapper';
import styles from './Card.module.scss';
import HideFromUser from 'src/components-v2/Helpers/HideFromUser/HideFromUser';

interface Props {
  title: string;
  subtitle?: string;
  link: string;
  linkText: string;
  icon: React.ReactNode;
}

const Card: React.FC<Props> = ({ title, subtitle, link, linkText, icon }) => {
  const actionCardProps = {
    className: cx(styles.card, 'hide-for-aeo'),
    title,
    subtitle,
    href: withTrailingSlash(link),
    ctaText: linkText,
    icon,
    ...(isExternalUrl(link)
      ? {}
      : { component: LinkWrapper, componentProps: { href: link } }),
  };

  return (
    <>
      <ActionCard {...actionCardProps} />
      <HideFromUser>
        <h4>{title}</h4>
        <p>{subtitle}</p>
        <a href={withTrailingSlash(link)}>{linkText}</a>
      </HideFromUser>
    </>
  );
};

Card.displayName = 'Card';

export default Card;
