import React from 'react';

interface Props {
  children: React.ReactNode;
}

// we have this component to make sure that the content is hidden from the user
// a screen reader, and search engine crawlers, but not from the markdown compiler
// the markdown compiler is used to compile our pages for Answer Engine Optimization (AEO)
const HideFromUser: React.FC<Props> = ({ children }) => {
  return (
    <span data-nosnippet className='hide-for-user'>
      {children}
    </span>
  );
};

export default HideFromUser;
